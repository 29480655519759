body {
    background-size: 100% 100%;
    min-height:120vh;
}

.pageSeparators {
    height: 100%;
    margin-top:9vh;
    margin-bottom:9vh;
    padding-top: 45vh;
    text-align: center;
    background: #eee;
}


.portSections {
    background-size: 100% 100%;
    min-height: 110vh;
}


