.header {
    height: 9vh;
    display: flex;
    padding-bottom: 0vh;
    margin-top: 0vh;
    background-color: dodgerblue;
    align-items: center;
    position: fixed;
    min-width: 1000vh;
    width: 100%;
    top: 0;
    font-size: 1.3rem;
    z-index: 1;
}

#kd {
    text-decoration: none;
    color: black;
}


.login {
    color: black;
    z-index: 15;
    margin-left: 6vh;
    border-color:midnightblue;
    border-width:0.63vh;
    height: 7vh;
    text-align: center;
    justify-content: center;
    width: 9vh;
    box-shadow: 2vh;
    font-size: 3.6vh;
    font-family: fantasy;
    background-color: deepskyblue;
    transition: all ease-in-out 0.27s;
    border-radius: 1vh;
}

    .login:hover {
        opacity: 0.9;
        background-color: rebeccapurple;
        color:black;
        border-radius: 1.5vh;
        
    }




.navBar {
    display: flex;
    -webkit-box-align: center;
}


.navHeader {
    color: black;
    justify-content: right;
    font-family: fantasy;
    margin-bottom: 0vh;
}

.NavTab {
    color:black;
    text-decoration:none;
    font-size:9.5vh;
    justify-content:center;
    font-family:Cambria, Cochin, Georgia, Times, Times New Roman, serif;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: auto;
}

li {
    display: inline;
    padding: 3.6vh;
    
}
