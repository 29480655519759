#about {
    padding-top: 12.24vh;
    margin-top: -9.18vh;
}

#aboutHeader {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    color:dodgerblue;

}

.aboutBlurbs {

}

#firstAbt {
    margin-left: 27vh;
    margin-top: 7.2vh;
    font-size: 3.6vh;
    color: cadetblue;
    transition: all 0.45s ease-in-out;
}
    #firstAbt:hover {
        -webkit-transform: scale(1.05);
    }

#secondAbt {
    margin-left: 36vh;
    margin-top: 4.32vh;
    font-size: 3.6vh;
    color: steelblue;
    transition: all 0.45s ease-in-out;
}
#secondAbt:hover {
    -webkit-transform: scale(1.05);
}

#thirdAbt {
    margin-left: 45vh;
    font-size: 3.6vh;
    margin-top: 4.32vh;
    color: teal;
    transition: all 0.45s ease-in-out;
}
#thirdAbt:hover {
    -webkit-transform: scale(1.05);
}

#fourthAbt {
    margin-left: 54vh;
    font-size: 3.6vh;
    margin-top: 4.32vh;
    color: lightseagreen;
    transition: all 0.45s ease-in-out;
}
#fourthAbt:hover {
    -webkit-transform: scale(1.05);
}

#fifthAbt {
    margin-top: 4.32vh;
    margin-left: 63vh;
    font-size: 3.6vh;
    color: cornflowerblue;
    transition: all 0.45s ease-in-out;
}
#fifthAbt:hover {
    -webkit-transform: scale(1.05);
}

#sixthAbt {
    margin-top: 4.32vh;
    margin-left: 72vh;
    font-size: 3.6vh;
    color: royalblue;
    transition: all 0.45s ease-in-out;
}
#sixthAbt:hover {
    -webkit-transform: scale(1.05);
}




