#projects {
    padding-top: 12.24vh;
    margin-top: -9.18vh;
}

#projectsHeader {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    color: dodgerblue;
}

.projectButtons {
    margin-left: 18vh;
    margin-top: 4.59vh;
    padding-top: 2vh;
    padding-right: 11.7vh;
    padding-left: 11.7vh;
    padding-bottom: 27.9vh;
    box-shadow: rgba(35, 75, 255, 0.93) 0.7837vh 0.7837vh, rgba(85, 113, 240, 0.93) 1.5674vh 1.5674vh, rgba(147, 166, 247, 0.93) 2.3511vh 2.3511vh, rgba(181, 194, 252, 0.93) 3.1348vh 3.1348vh, rgba(213, 220, 253, 0.93) 3.9185vh 3.9185vh;
    border-radius: 0.9vh;
    width: 45vh;
    height: 36vh;
    table-layout: fixed;
    background-color: #111cd6;
    transition: all 0.5s ease-in-out;
}

    .projectButtons:hover {
        -webkit-transform: scale(1.01);
        background-color: #1043eb;
    }

.projectHeaders {
    color: #1a9fc4;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 4.5vh;
}


#bTreeButton {
}


#bTreeHeader {
    
}

#airportButton {

}


#airportHeader {
    
}

#livingButton {
}



#livingHeader {
    
}

#heapButton {
}

#heapHeader {
    
}