@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.footer-social-icons {
    margin-top:0vh;
    margin-bottom:0vh;
    margin-left:2.88vh;
}

.social-icon {
    color: #fff;
}

.social-icons a {
    color: #fff;
    text-decoration: none;
}

.social-icons > li {
    display: list-item;
    margin-top:2.7vh;
    margin-left:4.9vh;
    padding: 0.2vh;
}

.fa-github {
    padding: 1.5vh 2vh;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    position: absolute;
    transition: all 0.153s ease-in-out;
    background-color: dodgerblue;
    margin-top: 6.507vh;
}

    .fa-github:hover {
        background-color: #171515;
        -webkit-transform: scale(1.17);
    }

.fa-linkedin {
    padding: 0.6vh 0.6vh;
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    transition: all 0.153s ease-in-out;
    position: absolute;
    background-color: dodgerblue;
    margin-top: 0vh;
}

    .fa-linkedin:hover {
        background-color: #0073a4;
        -webkit-transform: scale(1.17);
    }

.headerDiv {
    background-color: none;
}

#typer {
    position:relative;
    margin-left:81vh;
    z-index:1;
}

.typewriter {
    font-family: monospace;
    font-weight:bold;
    font-size: 4vh;
    text-align: start;
    color: dodgerblue;
    display: flex;
    justify-content: start;
    align-items: flex-start;
    min-height: 5vh;
    background: none;
    
}

#home {
    padding-top: 14vh;
    margin-top: -5.4vh;
    padding-bottom:2vh;
}

#homeButton {
    color: black;
    border: 0vh;
    background-color: dodgerblue;
    font-size: 3vh;
    cursor: pointer;
    margin-left: 54vh;
    margin-top: 6.3vh;
    transition: 0.5s;
}

    #homeButton:hover {
        opacity: 0.9;
        background-color: royalblue;
        border-radius: 1.5vh;
    }

#aboutButton {
    color: black;
    border: 0vh;
    background-color: dodgerblue;
    font-size: 3vh;
    cursor: pointer;
    transition: 0.5s;
}

    #aboutButton:hover {
        opacity: 0.9;
        background-color: royalblue;
        border-radius: 1.5vh;
    }

#skillsButton {
    color: black;
    border: 0vh;
    background-color: dodgerblue;
    font-size: 3vh;
    cursor: pointer;
    transition: 0.5s;
}

    #skillsButton:hover {
        opacity: 0.9;
        background-color: royalblue;
        border-radius: 1.5vh;
    }

#projectsButton {
    color: black;
    border: 0vh;
    background-color: dodgerblue;
    font-size: 3vh;
    cursor: pointer;
    transition: 0.5s;
}

    #projectsButton:hover {
        opacity: 0.9;
        background-color: royalblue;
        border-radius: 1.5vh;
    }

#contactButton {
    color: black;
    border: 0vh;
    background-color: dodgerblue;
    margin-bottom:4.59vh;
    font-size: 3vh;
    cursor: pointer;
    transition: 0.5s;
}

    #contactButton:hover {
        opacity: 0.9;
        background-color: royalblue;
        border-radius:1.5vh;
    }



#mainHeader {
    color: black;
    font-size: 13.5vh;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align:center;
    margin-top:1vh;
}

#hello {
    margin-left: 40vh;
    margin-bottom:-1.35vh;
    font-size: 4.5vh;
    margin-top: 4.5vh;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: dodgerblue;
}

#homePic {
    margin-left: 126vh;
    margin-top:-10vh;
    position: absolute;
    max-width: 100%;
    height:auto;
}


#resumeButton {
    margin-left: 7.93vh;
    border: 0vh;
    background-color: dodgerblue;
    font-size: 3.33vh;
    color: white;
    font-family: monospace;
    border-radius: 0.45vh;
    margin-top: -9vh;
    width: 18vh;
    position: absolute;
    transition: all 0.153s ease-in-out;
}

    #resumeButton:hover {
        background-color: deepskyblue;
        -webkit-transform: scale(1.08);
    }

#download {
    font-size:2.16vh;
    margin-top:0vh;
    padding-top:0vh;
}
/*#moreButton {
    color: black;
    border: 0vh;
    background-color: transparent;
    font-size: 3vh;
    cursor: pointer;
    margin-left: 81vh;
    margin-top: 34vh;
    transition: all 0.153s ease-in-out;
}
    #moreButton:hover {
        border-block: solid;
        -webkit-transform: scale(1.035);
        color:dodgerblue;
    }*/

#imA {
    margin-left: 87.4vh;
    font-size: 3vh;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


