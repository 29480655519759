@import url(https://fonts.googleapis.com/css?family=Lato);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.css);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

#contact {
    padding-top: 12.24vh;
    margin-top: -9.18vh;
}

.emailButton {
    background-color: white;
    border: double;
    border-radius: 1vh;
    color: steelblue;
    padding: 15px 32px;
    text-align: center;
    font-size: 2.7vh;
    transition: all 0.18s ease-in-out;
    margin-left:90vh;
    margin-top:25vh;
}
    .emailButton:hover {
        -webkit-transform: scale(1.02);
        background-color:lightsteelblue;
    }


#contactHeader {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    color: dodgerblue;
}

#contactBlurb1 {
    color: cornflowerblue;
    font-size: 3.5868vh;
    font-family: Cambria, Cochin, Georgia, Times, Times New Roman, serif;
    padding-top: 2.86944vh;
    margin-left: 18.60832vh;
}

#contactBlurb2 {
    color: royalblue;
    font-size: 3.5868vh;
    font-family: Cambria, Cochin, Georgia, Times, Times New Roman, serif;
    padding-top: 2.86944vh;
    margin-left: 91.60832vh;
}


element.style {
}
@media (max-width: 1080px)
.bottomResume {
    left: auto;
    right: 2.86944vh;
}

div {
    display:block;
}



.bottomResume {
    width: 40px;
    position: fixed;
    bottom: 27vh;
    left: auto;
    font-size: 3.6vh;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    font-family: 'Times New Roman', Times, serif;
    right: 40px;
    z-index: 10;
    color: var(--light-slate);
}
    
    .bottomResume::after {
        content: "";
        display: block;
        width: 0.72vh;
        height: 2vh;
        margin-top:-4.5vh;
        text-align:match-parent;
        margin-left:8.19vh;
        margin-bottom:-40.5vh;
        background-color: royalblue;
        padding-top:40vh;


    }

.bottomResumeOne a {
    margin: 20px auto;
    padding: 10px;
    font-family: var(--font-mono);
    font-size: var(--fz-xxs);
    color: royalblue;
    line-height: var(--fz-lg);
    margin-left: 8.1vh;
    letter-spacing: 0.01em;
    writing-mode: vertical-rl;
    text-decoration: none;
    text-orientation: upright;
    transition: all 0.153s ease-in-out;
    writing-mode: vertical-rl;
}
.bottomResumeOne a:hover {
    -webkit-transform: scale(1.09);
}











.socialMediaOne {
    position: fixed;
    bottom: 10vh;
    z-index: 10;
    margin-left:200vh;


    -webkit-box-align:center;
}

    .socialMediaOne::after {
        content: "";
        display: block;
        width: 0.72vh;
        height: 27vh;
        margin-top:490vh;
        margin-bottom: -12.6vh;
        background-color: royalblue;
        box-sizing: inherit;
        margin-left: -195vh;
    }

    .socialMediaOne ul {
        display: flex;
        flex-direction:column;
        justify-content: space-between;
        width: 100%;
        margin:0;
        padding: 0;
        float:left;
    }

    .socialMediaOne li {
        display: block;
        flex: 0 1 auto; /* Default */
        list-style-type: none;
        background: #fafafa;
    }



.social-icons-contacts a{
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin-left:-7.29vh;
    margin-top:2.7vh;
    margin-bottom:0vh;
    margin-top:-10vh;
    padding-bottom:13vh;
    list-style: none;
}



#linkedin {
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    position: fixed;
    transition: all 0.216s ease-in-out;
    background-color: transparent;
    margin-top: 465vh;
    margin-left: -194.5vh;
    width: 7.02vh;
    height: 6.7vh;
}

    #linkedin:hover {
        -webkit-transform: scale(1.26);
    }

#github {
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    position: fixed;
    transition: all 0.216s ease-in-out;
    background-color: transparent;
    margin-top: 465vh;
    margin-left: -194.5vh;
    width: 7.02vh;
    height: 6.7vh;
}

    #github:hover {
        -webkit-transform: scale(1.26);
    }

#mail {
    -o-transition: .5s;
    -ms-transition: .5s;
    -moz-transition: .5s;
    -webkit-transition: .5s;
    position: fixed;
    transition: all 0.216s ease-in-out;
    background-color: transparent;
    margin-top: 465vh;
    margin-left:-194.5vh;
    width: 6.03vh;
    height: 6.7vh;
}

    #mail:hover {
        -webkit-transform: scale(1.26);
    }



ul {
    
}
