@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

#skills {
    padding-top: 12.24vh;
    margin-top: -9.18vh;
}

#skillsHeader {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: center;
    color: dodgerblue;
    margin-bottom:0vh;
}

.skillsButtons {
    color: black;
    background-color: white;
    cursor: pointer;
    margin-left: 21vh;
    margin-top: 4.5vh;
    transition: all ease-in-out 0.27s;
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-right: 1vh;
    padding-left: 1vh;
    text-align: inherit;
    width: 27vh;
    box-shadow: 2vh;
    font-size: 3.6vh;
    font-family:fantasy;
    border-radius:1vh;
}

    .skillsButtons:hover {
        opacity: 0.9;
        background-color: dodgerblue;
        border-radius: 1.5vh;
        -webkit-transform: scale(1.08);
    }

#pythonButton {
}
 